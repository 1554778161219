"use client";

import { Header } from "containers/Header/Header";
import SignInPage from "containers/SignInPage";
import { AuthProvider } from "contexts/AuthProvider";

export default function Page() {
    return (
        <AuthProvider>
            <SignInPage header={<Header />} fullWidth />
        </AuthProvider>
    );
}
